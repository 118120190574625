@import "../../designSystem/styles/main.scss";

.container {
  grid-column: 1 / -1;
  background-color: $colour-primary-light;
  padding: 48px 0 64px;

  @include breakpoint-xxs {
    @include grid-xxs;
  }
  @include breakpoint-xs {
    @include grid-xs;
  }
  @include breakpoint-s {
    @include grid-s;
  }
  @include breakpoint-m {
    @include grid-m;
  }
  @include breakpoint-l {
    @include grid-l;
  }
}

.title {
  grid-column: 2 / -2;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
