@import "../../styles/main.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include breakpoint-xs {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }
}
