// Colours

// Primary
$colour-primary-main: #1f7fd1;
$colour-primary-light: #e4f2ff;
$colour-primary-dark: #093f7c;

// Secondary
$colour-secondary-main: #ffec00;
$colour-secondary-light: #fff7b2;
$colour-secondary-dark: #f4db00;

// Tertiary
$colour-tertiary-main: #f29100;
$colour-tertiary-light: #ffdfb7;
$colour-tertiary-dark: #e27e00;

// Extended
$colour-extended-main: #bbbbbb;
$colour-extended-light: #efefef;
$colour-extended-dark: #767676;

// Base
$colour-base-light: #ffffff;
$colour-base-dark: #363636;
$colour-base-dark-opacity: rgba(54, 54, 54, 0.9)
