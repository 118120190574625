@import "../styles/main.scss";

.common {
  border-bottom: 2px solid $colour-base-light;
  text-decoration: none;
  padding: 8px 0 6px 0;
  font-family: $font-family-secondary;
  font-size: 24px;
  line-height: 100%;
  color: $colour-extended-dark;
  &:hover {
    border-bottom: 2px solid $colour-primary-main;
    color: $colour-primary-main;
  }
  &:active {
    border-bottom: 2px solid $colour-primary-dark;
    color: $colour-primary-dark;
  }
}
