@import "../../styles/main.scss";

.container {
  border-radius: 8px;
  background-color: $colour-base-light;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
