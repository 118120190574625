@import "../styles/main.scss";

.common {
  margin: 0;
  font-family: $font-family-secondary;
  font-weight: 300;
  line-height: 150%;
}

// type
.p1 {
  font-size: 24px;
}

.p2 {
  font-size: 20px;
}

.footer {
  font-size: 16px;
}

// colour
.base__dark {
  color: $colour-base-dark;
}

.base__light {
  color: $colour-base-light;
}
