@import "../styles/main.scss";

.common {
  margin: 0;
  font-family: $font-family-primary;
  font-weight: 600;
  line-height: 150%;
}

// type
h1 {
  font-size: 40px;
  @include breakpoint-xs {
    font-size: 48px;
  }
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

// colour
.base__dark {
  color: $colour-base-dark;
}

.primary__dark {
  color: $colour-primary-dark;
}

.base__light {
  color: $colour-base-light;
}
